import { FormAnswer, renderCustomFieldDefaultString, useQuery } from "miter-utils";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import styles from "../../components/performance/Performance.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs, Button, Label, TableV2 } from "ui";
import { ESignatureItem, Form, MiterAPI } from "dashboard/miter";
import {
  ReviewWithFormResponseEntry,
  cleanResponsesFromBackend,
  generateReviewPeriodString,
} from "dashboard/utils/performance";
import { ForageRequest } from "backend/utils/forage/forage-types";
import {
  useActiveCompanyId,
  useLookupDepartment,
  useLookupPerformanceReviewSchedule,
} from "dashboard/hooks/atom-hooks";
import { ESignatureAnswerModal } from "dashboard/components/forms/FormSubmissionAnswersTable";
import { FormComponent } from "dashboard/components/forms/FormBuilderScreen";
import { ValueFormatterParams, ValueGetterParams } from "ag-grid-community";
import { AgGridTooltip } from "dashboard/components/agGridTable/AgGridTooltip";
import { truncate } from "lodash";

type PerformanceReportRoute = {
  id: string;
  question_id?: string;
};

const mapOfAssessmentTypeToLabel = {
  self_review: "Self review",
  direct_report_review: "Manager review",
};

export const PerformanceQuestionReport: React.FC = ({}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, question_id } = useParams<PerformanceReportRoute>();
  const query = useQuery();
  const reviewWindow = query.get("review-window");
  const assessmentType = query.get("assessment-type");
  const [questionText, setQuestionText] = useState("");
  const activeCompanyId = useActiveCompanyId();
  const [selectedAnswer, setSelectedAnswer] = useState<FormAnswer>();
  const [formField, setFormField] = useState<FormComponent | undefined>();
  const lookupPerformanceReviewSchedule = useLookupPerformanceReviewSchedule();
  const lookupDept = useLookupDepartment();

  useEffect(() => {
    if (!id) {
      navigate("/performance/review-schedules", { replace: true });
    }
  }, [id]);

  const reviewSchedule = lookupPerformanceReviewSchedule(id);

  useEffect(() => {
    const form = reviewSchedule?.[`${assessmentType}_form`] as Form;
    const formField = form?.components?.find((field) => field._id === question_id);
    setFormField(formField);
  }, [reviewSchedule, assessmentType, question_id]);

  useEffect(() => {
    if (!reviewSchedule || !assessmentType || !question_id) return;
    const form = reviewSchedule?.[`${assessmentType}_form`] as Form;
    if (!form) return;
    const formComponent = form.components.find((item) => item._id === question_id);
    if (!formComponent) return;
    setQuestionText(formComponent.name || "");
  }, [reviewSchedule, assessmentType, question_id]);

  useEffect(() => {
    if (!reviewWindow || !assessmentType) {
      navigate(`/performance/review-schedules/${id}/reports`, { replace: true });
    }
  }, [reviewWindow, assessmentType, id]);

  const renderBreadcrumbs = () => {
    if (!reviewSchedule) return;

    return (
      <Breadcrumbs
        crumbs={[
          { label: "Review schedules", path: "/performance/review-schedules" },
          {
            label: reviewSchedule.name,
            path: "/performance/review-schedules/" + reviewSchedule._id + "/reports",
          },
          {
            label: truncate(questionText || "Question", { length: 20 }),
            path: `${location.pathname}?review-window=${reviewWindow}&assessment-type=${assessmentType}`,
          },
        ]}
      />
    );
  };

  const renderFilters = () => {
    if (!reviewWindow || !assessmentType) return;
    const reviewWindowLabel = generateReviewPeriodString(reviewWindow);
    const assessmentTypeLabel = mapOfAssessmentTypeToLabel?.[assessmentType];
    if (!reviewWindowLabel || !assessmentTypeLabel) return;
    return (
      <div style={{ display: "flex", marginTop: 10 }}>
        <div style={{ marginRight: -50 }}>
          <Label label="Assessment" style={{ marginBottom: -5 }} />
          <div>{assessmentTypeLabel}</div>
        </div>
        <div>
          <Label label="Review cycle" style={{ marginBottom: -5 }} />
          <div>{reviewWindowLabel}</div>
        </div>
      </div>
    );
  };

  const getData = useCallback(
    async (query: ForageRequest) => {
      if (!reviewSchedule) return { data: [], next_page: null, prev_page: null };
      const res = await MiterAPI.performance_reviews.forage({
        ...query,
        filter: [
          { field: "review_cycle_id", value: reviewSchedule._id },
          ...(reviewWindow !== "undefined" ? [{ field: "review_period", value: reviewWindow }] : []),
        ],
        select: undefined,
      });
      const finalData = cleanResponsesFromBackend(res.data, question_id || "", assessmentType || "");
      return { ...res, data: finalData };
    },
    [activeCompanyId, reviewSchedule, reviewWindow, assessmentType]
  );

  const columns = useMemo(() => {
    return [
      {
        headerName: "Answer",
        field: "value",
        minWidth: 450,
        cellRenderer: (params: ValueFormatterParams<ReviewWithFormResponseEntry>) => {
          if (!formField) return "-";

          if (
            formField?.type === "esignature" &&
            (params.data?.response?.value as ESignatureItem)?.signature
          ) {
            return (
              <Button className="button-1 no-margin" onClick={() => setSelectedAnswer(params.data?.response)}>
                View
              </Button>
            );
          }

          return renderCustomFieldDefaultString(formField as $TSFixMe, params.data?.response);
        },
        valueGetter: (params: ValueGetterParams<ReviewWithFormResponseEntry>) => {
          const form = reviewSchedule?.[`${assessmentType}_form`] as Form;
          if (!form) return "-";
          const formField = form.components.find((field) => field._id === question_id);
          if (!formField) return "-";

          return renderCustomFieldDefaultString(formField as $TSFixMe, params.data?.response);
        },
      },
      {
        headerName: assessmentType === "self-review" ? "Submitter" : "Reviewee",
        field: "reviewee.full_name",
        type: "string",
      },
      ...(assessmentType === "self_review"
        ? []
        : [{ headerName: "Manager", field: "reviewer.full_name", type: "string" }]),
      {
        headerName: "Submitted on",
        field: `${
          assessmentType === "self_review" ? "self_review_submission" : "reviewer_submission"
        }.completed_at`,
        dataType: "date" as const,
        dateType: "timestamp" as const,
      },
      {
        headerName: "Department",
        field: "reviewee.department_id",
        valueFormatter: (params) => {
          return lookupDept(params.value)?.name || "-";
        },
        tooltipComponent: AgGridTooltip,
        tooltipValueGetter: () =>
          assessmentType === "direct_report_review" ? "Reviewee's department" : null,
        type: "string",
      },
    ];
  }, [formField, assessmentType]);

  return (
    <div className="page-content">
      <Helmet>
        <title>Performance Report | Miter</title>
      </Helmet>
      {reviewSchedule && formField && (
        <>
          <div className={"page-content-header " + styles["header"]}>
            {renderBreadcrumbs()}
            <h1>Responses</h1>
            <div className="flex-1"></div>
          </div>
          {renderFilters()}
          <h3 style={{ marginTop: 40, marginBottom: -45 }}>{questionText}</h3>

          <TableV2
            id="performance-responses-table"
            title="Responses"
            resource="performance responses"
            columns={columns}
            ssr={true}
            getData={getData}
            wrapperClassName="base-ssr-table"
            containerClassName={"timesheets-table-container"}
            hideSearch={true}
          />
          {selectedAnswer && (
            <ESignatureAnswerModal answer={selectedAnswer} onHide={() => setSelectedAnswer(undefined)} />
          )}
        </>
      )}
    </div>
  );
};
